h1 {
    // margin: auto;
    font-size: 30px;
    text-align: center;
}

.board-ul {
    display: flex;
    flex-wrap: wrap;
    width: 832px;
    margin: auto;
    border: 10px solid black;
}

.board-ul li {
    border: 2px solid black;
    background-color: green;
    width: 100px;
    height: 100px;
}

.board-ul div {
    margin-top: 25px;
}


.current-player {
    text-align: center;
    font-size: 30px;
}


// Piece styling

.white, .black, .moves {
    background-color: white;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: auto;
} 

.black {
    background-color: black;
} 

.moves {
    background-color: black;
    opacity: 0.1;
}